import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import Nav from '../nav/nav'
import { Layout, Breadcrumb } from 'antd'
import { withFirebase } from 'react-redux-firebase'
import Header from '../header/header'
import { AUTHROUTE } from '../../routes';

const { Content, Footer } = Layout;

class AdminLayout extends React.Component {

  render() {
    const { firebase, component: Component, ...routerProps } = this.props;
    const auth = firebase.auth();
    const authenticated = auth.currentUser;

    if (authenticated) {
      return (
        <Route
          {...routerProps}
          render={matchProps => (
            <div>
              <Layout style={{ minHeight: '100vh' }}>
                <Nav logo={this.props.logoUrl}/>
                <Layout>
                  <Header />
                  <Content style={{ margin: '0 16px' }}>
                    <Breadcrumb style={{ margin: '16px 0' }}>
                      <Breadcrumb.Item>{this.props.name}</Breadcrumb.Item>
                      <Breadcrumb.Item>{this.props.companyName}</Breadcrumb.Item>
                    </Breadcrumb>
                    <div style={{ padding: 24, minHeight: 360 }}>
                      <Component {...matchProps}/>
                    </div>
                  </Content>
                  <Footer style={{ textAlign: 'center' }}>
                    LIZARD.GLOBAL © 2019
                  </Footer>
                </Layout>
              </Layout>
            </div>
        )}/>
      )
  }

    return (
      <Redirect to={AUTHROUTE} />
    )
  };
}

export default withFirebase(AdminLayout);
