import './exploreBar.css';
import React, { Component } from 'react';
import { Layout, Menu, Icon, Row, Col, Select, Button, message } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { withRouter, Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import axios from 'axios';
import history from '../../utils/history';
import ReactGA from 'react-ga'
import { coordinates } from '../../utils/calc'
const Option = Select.Option;

class ExploreBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      travelFromCountry: '',
      travelFromCode: '',
      travelToCountry: '',
      travelToCode: '',
      countryList: [],
      refreshData: false,
    };
  }

  componentDidMount() {
    let countryList = [];
    // axios.get(`https://api.culturemee.com/api/countries/getAllCountriesForWeb`).then((response) => {
    axios.get(`https://api.culturemee.com/api/restcountries//getAllRestcountry`).then((response) => {
      countryList = response.data.data;
      /*countryList = response.data.data.filter(country => country.is_complete === true);
      countryList.sort((a, b) => a.name.localeCompare(b.name));*/
      this.setState({
        countryList,
        travelFromCountry: sessionStorage.getItem('travelFromCountry'),
        travelFromCode: sessionStorage.getItem('travelFromCode'),
        travelFrom3Code: sessionStorage.getItem('travelFrom3Code'),
        travelToCountry: sessionStorage.getItem('travelToCountry'),
        travelToCode: sessionStorage.getItem('travelToCode'),
        travelTo3Code: sessionStorage.getItem('travelTo3Code'),
      });
    }).catch((error) => {
      console.log({error});
    });
  }

  componentDidUpdate(prevprops, prevstate) {
    // console.log('update')
    if (prevstate.refreshData !== this.state.refreshData) {
      // console.log('here')
      let countryList = [];
      // axios.get(`https://api.culturemee.com/api/countries/getAllCountriesForWeb`).then((response) => {
      axios.get(`https://api.culturemee.com/api/restcountries//getAllRestcountry`).then((response) => {
        // console.log({response});
        countryList = response.data.data;
        /*countryList = response.data.data.filter(country => country.is_complete === true);*/
        this.setState({
          countryList,
          travelFromCountry: sessionStorage.getItem('travelFromCountry'),
          travelFromCode: sessionStorage.getItem('travelFromCode'),
          travelFrom3Code: sessionStorage.getItem('travelFrom3Code'),
          travelToCountry: sessionStorage.getItem('travelToCountry'),
          travelToCode: sessionStorage.getItem('travelToCode'),
          travelTo3Code: sessionStorage.getItem('travelTo3Code'),
        });
      }).catch((error) => {
        console.log({error});
      });
    }
  }

  handleChangeTravelFrom = (value, { key, props }) => {
    this.setState({ travelFromCountry: value, travelFromCode: key, travelFrom3Code: props.code3 });
  }

  handleChangeTravelTo = (value, { key, props }) => {
    this.setState({ travelToCountry: value, travelToCode: key, travelTo3Code: props.code3 });
  }

  // countryButtons = (query) => {
  //   let { countryList } = this.state;
  //   countryList = countryList.filter(country => (
  //     (query === '' || country.name.match(new RegExp(query, 'i'))) || country.alpha3Code.toLowerCase() === query.toLowerCase() &&
  //     ['AX', 'UM', 'IO', 'VG', 'VI', 'SH'].indexOf(country.alpha2Code) === -1
  //   ));
  // }

  // countryButtons(query = '') {
  //   const countries = this.props.data.get('countries');
  //   const filteredCountries = countries.filter(country => (
  //     (query === '' || country.name.match(new RegExp(query, 'i'))) || country.alpha3Code.toLowerCase() === query.toLowerCase() &&
  //     ['AX', 'UM', 'IO', 'VG', 'VI', 'SH'].indexOf(country.alpha2Code) === -1
  //   ));

  //   return filteredCountries.map(country => (
  //     <TouchableOpacity
  //       onPress={() => {

  //         let name = '';
  //       if(country.alpha2Code === 'AX') {
  //         name = country.name.replace(/[^\w\s]/gi, '_');
  //         } else {
  //           name = country.name;
  //         }

  //         console.log('press country', country );
  //         firebase.analytics().logEvent(name.replace(/[^0-9A-Za-z_]/g, '_'), { screen: 'Country' });
  //         this.props.navigation.navigate('Country', { country: country.alpha2Code });
  //       }}
  //       key={country.alpha2Code}
  //       activeOpacity={1}
  //     >
  //       <View style={{ height: 1, backgroundColor: '#98864D', position: 'absolute', top: 30, left: 0, right: 0, ...Platform.select({ android: { } }) }} />
  //       <View style={{ flex: 1, flexDirection: 'row', alignItems: 'center', justifyContent: 'center', height: 60, paddingHorizontal: 10 }}>
  //         {!['ALA', 'UMI', 'SHN'].includes(country.alpha3Code) &&
  //           <MapIcon
  //             name={'map-' + (country.alpha2Code.toLowerCase() === 'gb' ? 'uk' : country.alpha2Code.toLowerCase())}
  //             style={{ fontSize: (country.name.length > 20) ? 40 : 50, color: '#98864D', paddingLeft: 10, ...Platform.select({ android: { backgroundColor: '#E9E9EF' } }) }}
  //           />
  //         }
  //         <Text numberOfLines={1} style={{ paddingHorizontal: 10, fontSize: this.getFontSize(country.name.length), color: '#98864D', fontFamily: 'Source Sans Pro', ...Platform.select({ android: { backgroundColor: '#E9E9EF' } }) }}>
  //           {country.name.replace(/ \([^)]*\) /g, '')}
  //           {country.is_complete && country.is_complete && '*'}
  //         </Text>
  //       </View>
  //     </TouchableOpacity>
  //   )
  //   );
  // }

  handleExplore = (e) => {

    const { t, header, closeDrawer } = this.props;
    const { travelFromCountry, travelFromCode, travelFrom3Code, travelToCountry, travelToCode, travelTo3Code } = this.state;
    if (travelFromCode === '' || travelToCode === '' || travelFromCode === null || travelToCode === null) {
      return message.error(t('must_select_countries'));;
    } else {
      sessionStorage.setItem('travelFromCountry', travelFromCountry);
      sessionStorage.setItem('travelFromCode', travelFromCode);
      sessionStorage.setItem('travelFrom3Code', travelFrom3Code);
      sessionStorage.setItem('travelToCountry', travelToCountry);
      sessionStorage.setItem('travelToCode', travelToCode);
      sessionStorage.setItem('travelTo3Code', travelTo3Code);

      ReactGA.event({category: `Explore`, action: 'click', label: `pu:${window.location.pathname}|from:${travelFromCode}|to:${travelToCode}`})
      ReactGA.event({category: 'HeatMap', action: 'click', label: `pu:${window.location.pathname}|ct:Explore|rx:${coordinates(e).relative_x}|ry:${coordinates(e).relative_y}`})


      if (window.location.pathname === '/') {
        history.push('/culture-videos');
      } else {
        this.setState({ refreshData: !this.state.refreshData });
        // history.push(window.location.pathname);
        // window.location.reload();
        history.go(0);
        if (header) {
          closeDrawer();
        }
      }
    }
  }

  filterOption = (input, option) => {
    const { countryList } = this.state;
    if ((option.key && option.key.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
      (option.props.code3 && option.props.code3.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
      (option.props.value && option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0) ||
      (option.props.native && option.props.native.toLowerCase().indexOf(input.toLowerCase()) >= 0)) {
      // console.log('first', {input, option});
      return true;
    }
    // console.log('next', {input, option});
    // return countryList.filter(country => (
    //   country.name.match(new RegExp(input, 'i')) || country.nativeName.match(new RegExp(input, 'i')) || (country.alpha3Code.toLowerCase() === input.toLowerCase() &&
    //   ['AX', 'UM', 'IO', 'VG', 'VI', 'SH'].indexOf(country.alpha2Code) === -1)
    // ));

  }

  render() {
    const { t, header } = this.props;
    const { countryList, travelFromCountry, travelFromCode, travelToCountry, travelToCode } = this.state;
    return (
      <div>
        <Row
          className={header  ?  "exploreDrawerContainer" : "exploreContainer"}
          // gutter={26.09}
        >
          <Col xs={24} sm={24} md={9} lg={9} className="travelCol">
            <div className="travelContainer travelDivider">
              <ReactSVG
                className="travelIcon"
                src="/icons/Nationality.svg"
              />
              <Select
                // defaultValue={sessionStorage.getItem('travelFromCountry')}
                defaultValue={sessionStorage.getItem('travelFromCountry') !== null ? sessionStorage.getItem('travelFromCountry') : undefined}
                className="travelSelect"
                showSearch
                style={{ width: '100%', height: '100%' }}
                placeholder={t('travel_from')}
                notFoundContent="No results"
                optionFilterProp="children"
                onChange={this.handleChangeTravelFrom}
                // filterOption={(input, option) => {console.log({input, option}, option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0);return option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;}}
                filterOption={(input, option) => this.filterOption(input, option)}
                suffixIcon={(
                  <ReactSVG
                    className="travelSelectChevron"
                    src="/icons/Chevron Down.svg"
                  />
                )}
                // getPopupContainer={trigger => trigger.parentNode}
              >
                {Object.values(countryList).map((item) => {
                  const { name, alpha2Code, alpha3Code } = item;
                  const nativeName = item.nativeName;
                  // country.alpha3Code.toLowerCase() === query.toLowerCase() && ['AX', 'UM', 'IO', 'VG', 'VI', 'SH'].indexOf(country.alpha2Code) === -1
                  if (name !== travelToCountry || alpha2Code !== travelToCode) {
                    return (
                      <Option key={alpha2Code} code3={alpha3Code} native={nativeName} value={name}>{name}</Option>
                    );
                  }
                })}
              </Select>
            </div>
          </Col>
          <Col xs={24} sm={24} md={0} lg={0} className="">
            <div className="tripleDotLine">
              ● ● ●
            </div>
          </Col>
          <Col xs={24} sm={24} md={9} lg={9} className="travelCol">
            <div className="travelContainer">
              <ReactSVG
                className="travelIcon"
                src="/icons/Travel to.svg"
              />
              <Select
                defaultValue={sessionStorage.getItem('travelToCountry') !== null ? sessionStorage.getItem('travelToCountry') : undefined}
                className="travelSelect"
                showSearch
                style={{ width: '100%', height: '100%' }}
                placeholder={t('travel_to')}
                optionFilterProp="children"
                notFoundContent="No results"
                onChange={this.handleChangeTravelTo}
                // filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                filterOption={(input, option) => this.filterOption(input, option)}
                suffixIcon={(
                  <ReactSVG
                    className="travelSelectChevron"
                    src="/icons/Chevron Down.svg"
                  />
                )}
                // getPopupContainer={trigger => trigger.parentNode}
              >
                {Object.values(countryList).map((item) => {
                  const { name, alpha2Code, alpha3Code } = item;
                  const nativeName = item.nativeName;
                  if (name !== travelFromCountry || alpha2Code !== travelFromCode) {
                    return (
                      <Option key={alpha2Code} code3={alpha3Code} native={nativeName} value={name}>{name}</Option>
                    );
                  }
                })}
              </Select>
            </div>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} className="travelCol flexEnd">
            <Button className={header ? "exploreDrawerButton" : "exploreButton"} type="primary" onClick={this.handleExplore}>
              {t('explore')}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

const enhanced = compose(
  withFirebase,
  withRouter,
  connect(state => ({
    auth: state.firebase.auth,
  })),
);
export default enhanced(ExploreBar);
