import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: 'AIzaSyAPncTFHJTUS5FIss8Teni3KfCMPPtWaQ4',
  authDomain: 'culturemee-web.firebaseapp.com',
  databaseURL: 'https://culturemee-web.firebaseio.com',
  projectId: 'culturemee-web',
  storageBucket: 'culturemee-web.appspot.com',
  messagingSenderId: '823034700407'
}


firebase.initializeApp(firebaseConfig)

export default firebase
