import './session_error.css'
import React, { Component } from 'react'
import { Row, Col } from 'antd'
import Logo from '../../components/nav/components/logo';

export class SessionError extends Component {

  render() {
    return (
      <div className="session_error">
        <div className="top_gradient" />
        <div className="bottom_gradient" />
        <Row justify="center" type="flex">
          <Col xs={0} sm={0} md={24} lg={24}>
            <Logo size={306} />
          </Col>
          <Col xs={24} sm={24} md={0} lg={0}>
            <Logo size={220} />
          </Col>
          <Col className="error_text">
            Your session has expired. Please try to login through TMC again
          </Col>
        </Row>
      </div>
    )
  }

}

export default SessionError;
