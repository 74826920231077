import React from 'react';
import { Input, Checkbox, DatePicker, AutoComplete, Select, Upload, Rate } from 'antd';
// import BraftEditor from 'braft-editor';
import moment from 'moment';
import ReactSVG from 'react-svg';
import { render, renderUpload, renderConnected } from '../../../utils/form';
import * as validate from './validate';
// import LocationSearchInput from '../../../components/common-ui/LocationSearchInput';

const InputField = render(Input);
const OptionField = render(Checkbox);
const CalendarField = render(DatePicker);
const AutoCompleteField = render(AutoComplete);
const DropdownField = render(Select);
const TextAreaField = render(Input.TextArea);
const RateField = render(Rate);
// const HTMLEditorField = render(BraftEditor);
const UploadField = renderUpload(Upload);
// const GooglePlacesField = renderConnected(LocationSearchInput);

function getOptions(options) {
  return options && options.map(option => (
    <Select.Option key={option.key} value={option.key}>{option.text}</Select.Option>
  ));
}

export default function fieldConfig(options) {
  return [
    // ROW 1
    {
      layout: { span: 24 },
      name: 'header',
      excludeFromForm: true,
      className: 'feedbackFormHeader',
      style: { textAlign: 'left !important' },
      component: <div className="feedbackFormHeader">Send us your feedback</div>,
      // parentClassName: 'required-label-asterisk-desc',
    }, {
      layout: { span: 24 },
      name: 'content',
      excludeFromForm: true,
      className: 'feedbackFormText',
      style: { textAlign: 'left !important' },
      component: <div className="feedbackFormText">We love feedback so we can keep improving & build a better experience</div>,
      // parentClassName: 'required-label-asterisk-desc',
    }, {
      layout: { sm: 24, md: 24 },
      name: 'name',
      placeholder: 'Name *',
      className: 'feedbackFormInput',
      component: InputField,
      validate: [validate.required],
    }, {
      layout: { sm: 24, md: 24 },
      name: 'email',
      placeholder: 'E-mail *',
      className: 'feedbackFormInput',
      component: InputField,
      validate: [validate.required, validate.email],
    }, {
      layout: { span: 24 },
      name: 'rateQuestion',
      excludeFromForm: true,
      className: 'feedbackFormText',
      style: { textAlign: 'left !important' },
      component: <div className="feedbackFormText">Has this helped you to do business abroad?</div>,
      // parentClassName: 'required-label-asterisk-desc',
    }, {
      layout: { sm: 24, md: 24 },
      name: 'rate',
      // placeholder: 'How can we improve? *',
      className: 'feedbackFormStarContainer',
      character: <ReactSVG className="feedbackStarIcon" src="/icons/Star grey.svg" />,
      // defaultValue: 0,
      // initialValue: 0,
      // normalize: (value) => value && value.valueOf() || '',
      component: RateField,
      validate: [validate.required],
    }, {
      layout: { sm: 24, md: 24 },
      name: 'feedback',
      placeholder: 'How can we improve? *',
      className: 'feedbackFormInput',
      component: InputField,
      validate: [validate.required],
    },
  ];
}
