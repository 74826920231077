
export const coordinates = (e) => {

  const { pageX = 0, pageY= 0 } = e
  const { innerWidth, innerHeight } = window

  const relative_x = (pageX/innerWidth).toFixed(2)
  const relative_y = (pageY/innerHeight).toFixed(2)



  // console.log('inner width', window);
  // console.log('test')
  console.log('coordinates', window.innerWidth, pageX, pageY)

  return { relative_x, relative_y}
}
