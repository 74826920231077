import './nav.css'
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import Logo from './components/logo'
import { Layout, Menu, Icon } from 'antd'
import routes from '../../routes'

const { Sider } = Layout

export class Nav extends Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  }

  render() {
    const { location } = this.props

    return (
      <Sider
        style={{ minHeight: '100vh' }}
        collapsible
        collapsed={this.state.collapsed}
        onCollapse={this.onCollapse}
        theme="dark"
        className="sider"
      >
        <ReactCSSTransitionGroup
          transitionName="example"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={300}
        >
          {!this.state.collapsed &&
            <div key="logo" style={{ marginLeft: 15 }}>
              <Logo size={170} />
            </div>}
        </ReactCSSTransitionGroup>
        <Menu theme="dark" mode="inline" selectedKeys={[location.pathname]}>
          {routes.filter(route => route.menu === true).map(route => (
            <Menu.Item key={route.path}>
              <Link to={route.path}>
                <Icon type={route.icon} />
                <span className="nav-text">{route.label}</span>
              </Link>
            </Menu.Item>
          ))}
        </Menu>
      </Sider>
    )
  }
}

export default withRouter(Nav)
