import React from 'react';

const imgSrc = {
  sm: '/img/CultureMee Logo with Name Copy.png',
  md: '/img/CultureMee Logo with Name Copy@2x.png',
  lg: '/img/CultureMee Logo with Name Copy@3x.png',
};

const Logo = ({ size }) => {
  let imgSize = 'sm';
  if (size < 200) {
    imgSize = 'sm';
  } else if (size > 200 && size < 300) {
    imgSize = 'md';
  } else if (size > 300) {
    imgSize = 'lg';
  }

  return (
    <div className="logo">
      <img src={imgSrc[imgSize]} alt="logo" width={size} />
    </div>
  );
};

export default Logo;
