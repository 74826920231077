import { SubmissionError } from 'redux-form'
import history from '../../utils/history'
import { SystemError } from '../../utils/error'
import { ADMINROUTE , HOMEROUTE } from '../../routes'
import { sendVoucher } from '../../sendgrid'
import firebase_instance from '../../firebase'
import ReactGA from 'react-ga';

require('firebase/functions')

export const login = (creds, firebase) => {
    return async (dispatch, getState) => {
        try {
            await firebase.login(creds)
            history.push(ADMINROUTE)
        } catch (error) {
            if (error.code === "auth/argument-error") throw new SubmissionError({ _error: "Missing field... did you type in everything?" })
            else throw new SubmissionError({ _error: error.message })
        }
    }
}

export const signup = (creds, firebase) => {
    return async (dispatch, getState) => {
        try {
            const profile = {
                name: creds.name,
                email: creds.email,
                role: 'user',
            };
            await firebase.createUser(creds, profile);
            history.push(ADMINROUTE);
        } catch (error) {
            if (error.code === "auth/argument-error") throw new SubmissionError({ _error: "Missing field... did you type in everything?" })
            else throw new SubmissionError({ _error: error.message })
        }
    }
}

export const signout = (firebase) => {
    return async (dispatch, getState) => {
        try {
            await firebase.auth().signOut()
            history.push('/')
        } catch (error) {
            SystemError(error)
        }
    }
}

export async function hashValidation(creds, firebase) {

  try {
    const createCustomToken = firebase_instance.functions().httpsCallable('api/createToken')

    const token = await createCustomToken(creds)
    console.log('token', token)
    await firebase.login({token: token.data})
    // await firebase.updateEmail(creds.email)
    await firebase.reloadAuth()

    ReactGA.event({category: 'User', action: 'Login'})

    history.push(HOMEROUTE)
  }catch(error) {
    console.log('error', error)
    history.push('/session_error')
  }
}
