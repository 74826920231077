import './header.css';
import React, { Component } from 'react';
import { Layout, Menu, Icon, Row, Col, Button, Drawer } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';
import history from '../../utils/history';
import { coordinates } from '../../utils/calc'
import ExploreBar from '../exploreBar/exploreBar';
import ReactGA from 'react-ga';

const { Header: AntHeader } = Layout;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

let tmcLogoURL = 'culturemeelogo.png';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleMenu: false,
      visibleExplore: false,
      travelFrom: '',
      openKeys: [],
      tmcLogoURL: '',
    };
  }

  componentDidMount() {
    const { profile } = this.props;
    // let tmcLogoURL = '';
    if (profile && profile.tmc) {
      if (profile.tmc.toLowerCase().includes('gray') || profile.tmc.toLowerCase().includes('dawes')) {
        tmcLogoURL = 'Gray Dawes Group Logo@2x.png';
      } else if (profile.tmc.toLowerCase().includes('bcd') || profile.tmc.toLowerCase().includes('bcdtravel')) {
        // tmcLogoURL = 'bcd-travel-logo.png';
        tmcLogoURL = 'BCD_Travel_Logo.jpeg';
      }
    }
    this.setState({ tmcLogoURL });
  }

  componentDidUpdate(prevProps, prevState) {
    // if ('new storage location' !== 'old storage location') {
    //   this.onExploreClose();
    // }
  }

  showMenuDrawer = () => {
    this.setState({
      visibleMenu: true,
    });
  };

  onMenuClose = () => {
    this.setState({
      visibleMenu: false,
    });
  };

  showExploreDrawer = () => {
    this.setState({
      visibleExplore: true,
    });
  };

  onExploreClose = () => {
    this.setState({
      visibleExplore: false,
    });
  };

  handleMenuClick = (e) => {
    ReactGA.event({category: 'HeatMap', action: 'click', label: `pu:${window.location.pathname}|ct:Menu|rx:${coordinates(e).relative_x}|ry:${coordinates(e).relative_y}`})

    if (e.key === 'culture-videos') {
      this.setState({ openKeys: [], visibleMenu: false });
      history.push('/culture-videos');
    } else {
      this.setState({ visibleMenu: false });
      history.push('/'+e.key);
    }
  }

  collapseSubMenu = (e) => {
    // console.log('click ', e);
    const { openKeys } = this.state;
    if (openKeys[0] !== e.key) {
      this.setState({ openKeys: [e.key] });
    } else {
      this.setState({ openKeys: [] });
    }
  }

  mainButtonClick = () => {
    const url = 'https://www.gdg.travel/';
    window.open(url, '_blank');
    this.setState({ visibleMenu: false });
  }

  render() {
    const { t } = this.props;
    const { visibleMenu, visibleExplore, travelFrom, openKeys } = this.state;
    let travelCode = sessionStorage.getItem('travelTo3Code') || sessionStorage.getItem('travelToCode');

    return (
      <AntHeader className="headerContainer">
        <div className="headerTopLayer"/>
        <Row type="flex" justify="space-between" className="headerRoom">
          <Col xs={0} sm={0} md={6} lg={6}>
            <Button className="headerLogoButton" onClick={() => this.mainButtonClick()}>
              <img src={`/img/${tmcLogoURL}`} alt="tmcLogo" width={tmcLogoURL === 'culturemeelogo.png' ? 130 : 188} />
            </Button>
          </Col>
          <Col xs={0} sm={0} md={18} lg={18} className="headerCol">
            <ExploreBar t={t} />
          </Col>
          <Col xs={2} sm={2} md={0} lg={0}>
             <ReactSVG
              className="headerIcon"
              src="/icons/Menu.svg"
              onClick={this.showMenuDrawer}
            />
            <Drawer
              placement="left"
              closable={false}
              onClose={this.onMenuClose}
              visible={visibleMenu}
              width={295}
            >
              <div className="tmcMenuDrawer" onClick={() => this.mainButtonClick()}>
                <img src={`/img/${tmcLogoURL}`} alt="tmcLogo" width={tmcLogoURL === 'culturemeelogo.png' ? 180 : 220} />
              </div>
              <Menu
                onClick={this.handleMenuClick}
                style={{ width: '100%' }}
                // defaultSelectedKeys={['1']}
                // defaultOpenKeys={['sub1']}
                // theme="dark"
                mode="inline"
                openKeys={openKeys}
              >
                <Menu.Item className="menuDrawerItem" key="culture-videos">{t('culture_videos')}</Menu.Item>
                <SubMenu onTitleClick={this.collapseSubMenu} key="sub1" className="subMenuDrawerItem" title={t('scratch_the_surface')} >
                  <Menu.Item className="menuDrawerItem withIcon" key="culture-overview">
                    <ReactSVG
                      className="subMenuDrawerIcon"
                      src="/icons/Culture Overview.svg"
                    />
                    {t('culture_overview')}
                  </Menu.Item>
                  <Menu.Item className="menuDrawerItem withIcon" key="business-culture">
                    <ReactSVG
                      className="subMenuDrawerIcon"
                      src="/icons/Business Culture.svg"
                    />
                    {t('business_culture')}
                  </Menu.Item>
                  <Menu.Item className="menuDrawerItem withIcon" key="general-dos-&-donts">
                    <ReactSVG
                      className="subMenuDrawerIcon"
                      src="/icons/Do_s _ Don_ts.svg"
                    />
                    {t('general_dos_&_donts')}
                  </Menu.Item>
                  <Menu.Item className="menuDrawerItem withIcon" key="duty-of-care">
                    <ReactSVG
                      className="subMenuDrawerIcon"
                      src="/icons/Duty of Care.svg"
                    />
                    {t('duty_of_care')}
                  </Menu.Item>
                </SubMenu>
                <SubMenu onTitleClick={this.collapseSubMenu} key="sub2" className="subMenuDrawerItem" title={t('go_deeper')} >
                  <Menu.Item className="menuDrawerItem withIcon" key="compare-your-culture">
                    <ReactSVG
                      className="subMenuDrawerIcon"
                      src="/icons/Compare Your Culture.svg"
                    />
                    {t('compare_your_culture')}
                  </Menu.Item>
                  <Menu.Item className="menuDrawerItem withIcon" key="business-negotiation">
                    <ReactSVG
                      className="subMenuDrawerIcon"
                      src="/icons/Business Negotiation.svg"
                    />
                    {t('business_negotiation')}
                  </Menu.Item>
                </SubMenu>
                <SubMenu onTitleClick={this.collapseSubMenu} key="sub3" className="subMenuDrawerItem" title={t('talk_to_the_pros')} >
                  <Menu.Item className="menuDrawerItem withIcon" key="culture-consultant">
                    <ReactSVG
                      className="subMenuDrawerIcon"
                      src="/icons/Culture Consultant.svg"
                    />
                    {t('culture_consultant')}
                  </Menu.Item>
                  <Menu.Item className="menuDrawerItem withIcon" key="language-interpreter">
                    <ReactSVG
                      className="subMenuDrawerIcon"
                      src="/icons/Language Interpreter.svg"
                    />
                    {t('language_interpreter')}
                  </Menu.Item>
                </SubMenu>
              </Menu>
            </Drawer>
          </Col>
          <Col xs={7} sm={7} md={0} lg={0}>
            <div className="headerExplore" onClick={this.showExploreDrawer}>
              {travelCode !== '' && travelCode}
              <ReactSVG
                className="headerIcon marginLeft"
                src="/icons/Travel to.svg"
              />
            </div>
            <Drawer
              placement="right"
              closable={false}
              onClose={this.onExploreClose}
              visible={visibleExplore}
              width={295}
            >
              <div className="headerExploreDrawer">
                <ExploreBar t={t} header={true} closeDrawer={this.onExploreClose} />
              </div>
            </Drawer>
          </Col>
        </Row>
      </AntHeader>
    )
  }
}

const enhanced = compose(
  connect(null, null),
  withTranslation(),
);

export default enhanced(Header);
