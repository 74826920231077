import './components/common.css';
import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import layouts from './components/layouts';
import routes from './routes';
import Loading from './components/common-ui/loading';
import withTracker from './utils/withTracker'
import ReactGA from 'react-ga'
// import i18n (needs to be bundled ;))
import './i18n';

const getRoute = (route) => {
  const Layout = layouts[route.layout] || layouts.DefaultLayout;
  return <Layout {...route} />;
};


class App extends Component {

  render() {
    const { auth: { isLoaded, uid, email } } = this.props;

    if (!isLoaded) {
      // return <div>initializing firebase...</div>;
      return (
        <div style={{ height: '100vh', width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Loading pastDelay={1} />
        </div>
      );
    }

    return (
      <div className="app">
        <Switch>
          {routes.map(route => getRoute(route))}
          <Redirect path="*" to="/"/>
        </Switch>
      </div>
    );
  }
}

const enhanced = compose(
  connect(({ firebase: { auth } }) => ({ auth })),
);

export default enhanced(App);
