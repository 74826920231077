import React from 'react';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {Layout} from 'antd';
import axios from 'axios';
import Header from '../header/header';
import {Route, Redirect} from 'react-router-dom';
import {withFirebase} from 'react-redux-firebase'
import {AUTHROUTE} from '../../routes';
import {SessionError} from '../../containers/session_error/session_error'
import Spinner from "../common-ui/spinner";

const {Content, Footer} = Layout;

class HomeLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      funFactsList: [' '],
    };
  }

  componentDidMount() {
    sessionStorage.clear();

    axios.get(`https://api.culturemee.com/api/countries/getAllCountriesForWeb`).then((response) => {
      // console.log({response});
      const countryList = response.data.data;
      // console.log({countryList});
      let randomIndex = Math.floor(Math.random() * countryList.length) + 1;
      let {code} = countryList[randomIndex];
      axios.get(`https://api.culturemee.com/api/countries/getFactsByCodeAndName/${code}/Fun Facts`).then((response) => {
        // console.log({response});
        let funFactsList = response.data.data.map(item => item.value);
        this.setState({funFactsList});
      }).catch((error) => {
        console.log({error});
      });
    }).catch((error) => {
      console.log({error});
    });
  }

  render() {
    const {firebase, component: Component, ...routerProps} = this.props;
    const {funFactsList} = this.state;
    const auth = firebase.auth();
    const authenticated = auth.currentUser;

    firebase.auth().signInWithEmailAndPassword('onkarskore@gmail.com', '123456')
      .then(() => {
        console.log('Logged in');
      });
    //
    // console.log('in homs layout')
    // if (!authenticated) {
    //    console.log('not authenticated')
    //   return <Redirect to={AUTHROUTE} />
    // }

    if (!authenticated) {
      return <Spinner size="big" />
    }

    return (
      <Route
        {...routerProps}
        render={matchProps => (
          <div>
            <Layout style={{minHeight: '100vh'}}>
              <Layout className="homeBackground">
                <div className="homeTopGradientLayer"/>
                <div className="homeBottomGradientLayer"/>
                <Content style={{margin: '0 16px', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <div style={{paddingTop: 24, paddingBottom: 24, minHeight: 360}}>
                    <Component {...matchProps} funFactsList={funFactsList}/>
                  </div>
                </Content>
              </Layout>
            </Layout>
          </div>
        )}
      />
    );
  }
}

// const enhanced = compose(
//   connect((state, props) => {
//     let funFactsList = [];
//     axios.get(`https://api.culturemee.com/api/countries/getAllCountriesForWeb`).then((response) => {
//       // console.log({response});
//       const countryList = response.data.data;
//       let randomIndex = Math.floor(Math.random() * countryList.length) + 1;
//       let { code } = countryList[randomIndex];
//       axios.get(`https://api.culturemee.com/api/countries/getFactsByCodeAndName/${code}/Fun Facts`).then((response) => {
//         // console.log({response});
//         funFactsList = response.data.data.map(item => item.value);
//         // this.setState({ funFactsList });
//       }).catch((error) => {
//         console.log({error});
//       });
//     }).catch((error) => {
//       console.log({error});
//     });
//     return {
//       funFactsList,
//     }
//   }),
// );

// export default enhanced(HomeLayout);
export default withFirebase(HomeLayout);
