import Loadable from 'react-loadable'
import Loading from './components/common-ui/loading'
import withTracker from './utils/withTracker'
import withBillingStats from './utils/withBillingStats'
import { compose } from 'redux';

export const AUTHROUTE = 'signin'
export const ADMINROUTE = 'admin'
export const HOMEROUTE = "/"

const getComponent = (component) =>
  Loadable({
    loader: () => import (`./containers/${component}/${component}`),
    loading: Loading
})

const enhanced = compose(
  withTracker,
  withBillingStats(),
)

export default [
  {
    key: 'session_error',
    layout: 'ErrorLayout',
    exact: true,
    path: '/session_error',
    component: getComponent('session_error'),
    menu: false,
  },
  // {
  //   key: 'auth',
  //   layout: 'AuthLayout',
  //   exact: true,
  //   path: '/signin',
  //   component: getComponent('auth'),
  //   menu: false,
  // },
  {
    key: 'tmc_auth',
    layout: 'DefaultLayout',
    exact: true,
    path: '/tmc/:travelFromCode?/:travelToCode?',
    component: getComponent('tmc_login'),
    menu: false,
  },
  // }, {
  //   key: 'form',
  //   label: 'FORM EXAMPLE',
  //   icon: 'form',
  //   layout: 'AdminLayout',
  //   exact: true,
  //   path: '/admin/form-example',
  //   component: getComponent('form'),
  //   menu: true,
  // },
  // {
  //   key: 'dashboard',
  //   label: 'DASHBOARD',
  //   icon: 'dashboard',
  //   layout: 'AdminLayout',
  //   exact: true,
  //   path: '/admin',
  //   component: getComponent('dashboard'),
  //   menu: true,
  // },
  {
    key: 'main',
    label: 'MAIN',
    icon: '',
    layout: 'MainLayout',
    exact: true,
    path: '/culture-videos',
    component: enhanced(getComponent('cultureVideos')),
    menu: true,
  }, {
    key: 'main',
    label: 'MAIN',
    icon: '',
    layout: 'MainLayout',
    exact: true,
    path: '/culture-overview',
    component: enhanced(getComponent('cultureOverview')),
    menu: true,
  }, {
    key: 'main',
    label: 'MAIN',
    icon: '',
    layout: 'MainLayout',
    exact: true,
    path: '/business-culture',
    component: enhanced(getComponent('businessCulture')),
    menu: true,
  }, {
    key: 'main',
    label: 'MAIN',
    icon: '',
    layout: 'MainLayout',
    exact: true,
    path: '/general-dos-&-donts',
    component: enhanced(getComponent('generalDosDonts')),
    menu: true,
  }, {
    key: 'main',
    label: 'MAIN',
    icon: '',
    layout: 'MainLayout',
    exact: true,
    path: '/duty-of-care',
    component: enhanced(getComponent('dutyOfCare')),
    menu: true,
  }, {
    key: 'main',
    label: 'MAIN',
    icon: '',
    layout: 'MainLayout',
    exact: true,
    path: '/compare-your-culture',
    component: enhanced(getComponent('compareYourCulture')),
    menu: true,
  }, {
    key: 'main',
    label: 'MAIN',
    icon: '',
    layout: 'MainLayout',
    exact: true,
    path: '/business-negotiation',
    component: enhanced(getComponent('businessNegotiation')),
    menu: true,
  }, {
    key: 'main',
    label: 'MAIN',
    icon: '',
    layout: 'MainLayout',
    exact: true,
    path: '/culture-consultant',
    component: enhanced(getComponent('cultureConsultant')),
    menu: true,
  }, {
    key: 'main',
    label: 'MAIN',
    icon: '',
    layout: 'MainLayout',
    exact: true,
    path: '/language-interpreter',
    component: enhanced(getComponent('languageInterpreter')),
    menu: true,
  }, {
    key: 'home',
    label: 'HOME',
    icon: 'home',
    layout: 'HomeLayout',
    exact: true,
    path: '/',
    component: enhanced(getComponent('home')),
    menu: true,
  }
]
