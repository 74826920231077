import DefaultLayout from './DefaultLayout';
import AuthLayout from './AuthLayout';
import AdminLayout from './AdminLayout';
import HomeLayout from './HomeLayout';
import MainLayout from './MainLayout';
import NewMainLayout from './NewMainLayout';
import ErrorLayout from './ErrorLayout';

const layouts = {
  DefaultLayout,
  AuthLayout,
  AdminLayout,
  HomeLayout,
  MainLayout,
  NewMainLayout,
  ErrorLayout
};

export default layouts;
