import React, { Component } from 'react';
import firebase from '../firebase'
// export const sendPageView = (firebase, firestore, page) => {
//   const auth = firebase.auth()
//   const { uid, email } = auth.currentUser
//
//   console.log('current user', auth.currentUser, firebase.auth)
//   if (auth) {
//     firestore.collection('/statistics').add(
//       { email, page }
//     ).then(result => console.log('firestore result', result))
//     .catch(error => console.log('firestore error', error))
//   }
//
// }

const firestore = firebase.firestore()
const auth = firebase.auth()

export const withBillingStats = (props) => (
  data = {}
) => WrappedComponent => {

  class BillingHOC extends Component {

    componentDidMount() {

      if (auth !== null) {
        const { currentUser: {email, uid}} = auth
        firestore.collection('/statistics').add({uid, email, page: this.props.location.pathname, accessedAt: firebase.firestore.FieldValue.serverTimestamp()})
      }
    }

    componentWillReceiveProps(nextProps) {
      const currentPage = this.props.location.pathname;
      const nextPage = nextProps.location.pathname;
      console.log('track nextProps', nextProps)

      if (currentPage !== nextPage) {
        if (auth !== null) {
          const { currentUser: {email, uid}} = auth
          firestore.collection('/statistics').add({uid, email, page: this.props.location.pathname})
        }
      }
    }

    render() {
        return (
          <WrappedComponent
            {...this.props}
          />
        )
      }
  }

  return BillingHOC
}


export default withBillingStats();
