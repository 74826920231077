import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { Layout } from 'antd'
import { withFirebase } from 'react-redux-firebase'
import './layouts.scss'

const { Content, Footer, Sider } = Layout;

const AuthLayout = ({
  component: Component,
  firebase,
  ...rest
}) => {
  const auth = firebase.auth();
  const authenticated = auth.currentUser;

  if (!authenticated) {
    return (
      <Route
        {...rest}
        render={matchProps => (
        <Layout style={{ minHeight: '100vh' }}>
          {/*<Sider
            className="authsider"
            width="50%"
            breakpoint="lg"
            collapsedWidth="0"
            trigger={null}
          >
          </Sider>*/}
          <Layout>
            <Content>
              <Component {...matchProps}/>
            </Content>
            {/*<Footer style={{ textAlign: 'center' }}>
              LIZARD.GLOBAL © 2019
            </Footer>*/}
          </Layout>
        </Layout>
      )}/>
    )
  }

  return (
    <Redirect to="/admin" />
  )
};

export default withFirebase(AuthLayout);
