import React from 'react';
import { Layout } from 'antd';
import Header from '../header/header';
import { Route, Redirect } from 'react-router-dom';

const { Content, Footer } = Layout;

class ErrorLayout extends React.Component {
  constructor(props) {
    super(props);
  }



  render() {
    const { component: Component, ...routerProps } = this.props;

    return (
      <Route
        {...routerProps}
        render={matchProps => (
          <div>
            <Layout style={{ minHeight: '100vh' }}>
              <Layout className="homeBackground">
                <div className="homeTopGradientLayer" />
                <div className="homeBottomGradientLayer" />
                <Content style={{ margin: '0 16px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <div style={{ paddingTop: 24, paddingBottom: 24, minHeight: 360 }}>
                    <Component {...matchProps} />
                  </div>
                </Content>
              </Layout>
            </Layout>
          </div>
        )}
      />
    );
  }
}

export default ErrorLayout;
