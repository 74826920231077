import './feedbackButton.css';
import React, {Component} from 'react';
import {Layout, Menu, Icon, Row, Col, Select, Button, Modal, Form, Input, notification} from 'antd';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {withFirebase} from 'react-redux-firebase';
import {withRouter, Link} from 'react-router-dom';
import ReactSVG from 'react-svg';
import axios from 'axios';
import {withTranslation} from 'react-i18next';
import {submit, reset} from 'redux-form';
import {pathOr} from 'ramda';
import FormFeedback from './components/FormFeedback';
import history from '../../utils/history';
import {coordinates} from '../../utils/calc';
import ReactGA from 'react-ga';

const Option = Select.Option;

const FORMNAME = 'formfeedback';

class FeedbackButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      submitting: false,
      record: {},
    };
  }

  componentDidMount() {
    const {profile} = this.props;
    let record = {};
    if (profile) {
      record = {
        /*name: profile.name || null,
        email: profile.email || null,*/
      }
    }
    this.setState({record});
  }

  componentDidUpdate(prevprops, prevstate) {

  }

  saveFormData = () => {
    this.props.dispatch(submit(FORMNAME));
  }

  submitForm = (values) => {

    let fullname = values.name.split(' ');
    const firstname = fullname.length > 1 ? fullname[0] : '';
    const lastname = fullname[fullname.length - 1];

    this.setState({submitting: true});
    notification.warning({
      message: 'Feedback form submitted',
      description: 'In process...',
    })
    // this.props.dispatch(reset(FORMNAME));
    // console.log('feedback', values, {firstname}, {lastname});
    // setTimeout(() => this.setState({ submitting: false }), 2000);
    // return;

    fetch('https://api.culturemee.com/api/feedbacks/addFeedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        email: values.email,
        firstname,
        lastname,
        feedback: values.feedback,
        rating: values.rate
      })
    })
      .then(response => response.json())
      .then((responseJson) => {
        if (responseJson.success) {
          this.setState({submitting: false, visible: false});
          notification.success({
            message: `Success`,
            description: `Feedback form submitted successfully!`,
          });
          this.props.dispatch(reset(FORMNAME));
        }
      })
      .catch((error) => {
        console.error(error);
        notification.error({
          message: `Error`,
          description: `Something is wrong with the form`,
        });
      });

    setTimeout(() => this.setState({submitting: false, visible: false}), 2000);
    return;
  }

  showModal = (e) => {
    ReactGA.event({
      category: 'HeatMap',
      action: 'click',
      label: `pu:${window.location.pathname}|ct:Feedback|rx:${coordinates(e).relative_x}|ry:${coordinates(e).relative_y}`
    })

    this.setState({
      visible: true,
    });
  }

  handleOk = (e) => {
    // console.log(e);
    // this.setState({
    //   visible: false,
    // });
  }

  handleCancel = (e) => {
    // console.log(e);
    this.props.dispatch(reset(FORMNAME));
    this.setState({
      visible: false,
    });
  }

  render() {
    const {t, header} = this.props;
    const {visible, submitting, record} = this.state;
    return (
      <div>
        <Button className="feedbackButton" onClick={(e) => this.showModal(e)}>Feedback</Button>
        <Modal
          visible={visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          bodyStyle={{padding: '20px', borderRadius: '10px'}}
          wrapClassName="feedbackFormModalWrapper"
          footer={[
            '',
            <Button className="feedbackSubmitButton" key="submit" type="primary" onClick={this.saveFormData}
                    loading={submitting}>{t('submit')}</Button>,
          ]}
        >
          <FormFeedback
            form={FORMNAME}
            submit={this.submitForm}
            record={record}
          />
        </Modal>
      </div>
    );
  }
}

const enhanced = compose(
  withFirebase,
  withRouter,
  withTranslation(),
  connect(null, null),
);
export default enhanced(FeedbackButton);
