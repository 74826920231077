import React from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { Layout, Modal } from 'antd';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import Header from '../header/header';
import Footer from '../footer/footer';
import Sider from '../sider/sider';
import Loading from '../common-ui/loading';
import FeedbackButton from '../feedbackButton/feedbackButton';
import history from '../../utils/history';
import { AUTHROUTE } from '../../routes';
import { SessionError } from '../../containers/session_error/session_error';
const { Content } = Layout;

// let path = window.location.pathname.substring(1);
// console.log('MainLayout', {path})

class MainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryData: [],
      travelFromData: [],
      travelToData: [],
    };
  }

  componentDidMount() {
    // console.log('Mount', this.props.firebase.auth().currentUser);
    if (sessionStorage.getItem('travelFromCode') === undefined || sessionStorage.getItem('travelFromCode') === null) {
      history.push('/');
    }
    axios.get('https://api.culturemee.com/api/countries/getAll').then((response) => {
      // console.log({response});
      const countryData = response.data.data;
      const travelFromData = countryData.filter(item => item.code === sessionStorage.getItem('travelFromCode'))[0];
      const travelToData = countryData.filter(item => item.code === sessionStorage.getItem('travelToCode'))[0];
      // console.log('travelFromData', travelFromData);
      // console.log('travelToData', travelToData);
      this.setState({ countryData, travelFromData, travelToData });
    }).catch((error) => {
      console.log({error});
    });
  }

  componentDidUpdate(prevprops, prevstate) {
    const { travelFromData, travelToData } = this.state;
    const travelFromCode = sessionStorage.getItem('travelFromCode');
    const travelFromCountry = sessionStorage.getItem('travelFromCountry');
    const travelToCode = sessionStorage.getItem('travelToCode');
    const travelToCountry = sessionStorage.getItem('travelToCountry');

    // console.log('Update', {travelFromData, travelToData});

    if (travelFromData && travelToData) {
      if (travelFromData.code !== travelFromCode || travelToData.code !== travelToCode) {
        this.updateCountrySelected();
      }
    }
    // else if (!travelFromData && !travelToData) {
    //   Modal.error({
    //     title: 'No informations for specific country',
    //     content: `Currently both countries ${travelFromCountry} & ${travelToCountry} have no informations`,
    //     onOk() {
    //       sessionStorage.clear();
    //       history.push('/');
    //     },
    //   });
    // } else if (!travelFromData && travelToData) {
    //   Modal.error({
    //     title: 'No informations for specific country',
    //     content: `Currently the country ${travelFromCountry} have no informations`,
    //     onOk() {
    //       sessionStorage.clear();
    //       history.push('/');
    //     },
    //   });
    // } else if (travelFromData && !travelToData) {
    //   Modal.error({
    //     title: 'No informations for specific country',
    //     content: `Currently the country ${travelToCountry} have no informations`,
    //     onOk() {
    //       sessionStorage.clear();
    //       history.push('/');
    //     },
    //   });
    // }
  }

  updateCountrySelected = () => {
    const { countryData } = this.state;
    if (countryData.length > 0) {
      this.setState({
        travelFromData: countryData.filter(item => item.code === sessionStorage.getItem('travelFromCode'))[0],
        travelToData: countryData.filter(item => item.code === sessionStorage.getItem('travelToCode'))[0],
      });
    }
  }

  render() {
    const { firebase, profile, component: Component, ...routerProps } = this.props;
    const { countryData, travelFromData, travelToData } = this.state;
    const auth = firebase.auth();
    const authenticated = auth.currentUser;

    let path = window.location.pathname.substring(1);

    if (!authenticated) {
      return <SessionError />
      // return <Redirect to={AUTHROUTE} />
    }

    if (countryData.length < 1) {
      return <Loading pastDelay={1} />;
    }

    // console.log({travelFromData, travelToData});

    return (
      <Route
        {...routerProps}
        render={matchProps => (
          <div>
            <Layout style={{ minHeight: '100vh' }}>
              <Layout>
                <Header profile={profile}>Header</Header>
                <Layout>
                  <Sider width={400} path={path}>Sider</Sider>
                  <Content className="mainLayoutContent">
                    <div className="mainLayoutComponentContainer">
                      <Component {...matchProps} travelFromData={travelFromData} travelToData={travelToData} profile={profile} />
                    </div>
                  </Content>
                </Layout>
                <Footer>Footer</Footer>
                <FeedbackButton profile={profile} />
              </Layout>
            </Layout>
          </div>
        )}
      />
    );
  }
}

const enhanced = compose(
  connect(state => ({
    profile: state.firebase.profile,
  })),
  withFirebase,
);

export default enhanced(MainLayout);
