import './footer.css';
import React, { Component } from 'react';
import { Layout, Menu, Icon, Row, Col } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { withRouter, Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import { withTranslation } from 'react-i18next';
import { signout } from '../../containers/auth/actions';
import { AUTHROUTE } from '../../routes';
import Logo from '../nav/components/logo';
import ReactGA from 'react-ga';
import { coordinates } from '../../utils/calc'

const { Footer: AntFooter } = Layout;
const SubMenu = Menu.SubMenu;

const socialURL = {
  facebook: 'https://www.facebook.com/culturemee/',
  twitter: 'https://twitter.com/culturemee',
  linkedin: 'https://www.linkedin.com/company/culturemee/',
  youtube: 'https://www.youtube.com/channel/UCDG7UjBqSXiF6p-kR-mFDEw',
  instagram: 'https://www.instagram.com/culturemee/',
}

class Footer extends Component {
  state = {}

  openLink = (e, type) => {
    const url = socialURL[type];

    ReactGA.event({category: 'HeatMap', action: 'click', label: `pu:${window.location.pathname}|ct:${type}|rx:${coordinates(e).relative_x}|ry:${coordinates(e).relative_y}`})
    ReactGA.event({category: `SocialMedia`, action: 'onClick', label: `pu:${window.location.pathname}|value:${type}` })
    window.open(url, '_blank');
  }

  openExternalLink = (e) => {
    const url = 'https://culturemee.com/';
    ReactGA.event({category: `ExternalLinks`, action: 'onClick', label: `pu:${window.location.pathname}|url:${url}` })
    ReactGA.event({category: 'HeatMap', action: 'click', label: `pu:${window.location.pathname}|ct:ExternalLinks|rx:${coordinates(e).relative_x}|ry:${coordinates(e).relative_y}`})

    window.open(url, '_blank');
  }

  render() {
    const { t } = this.props;

    return (
      <AntFooter className="footerContainer">
        <Row type="flex" justify="space-between" className="footerRoom">
          <Col xs={24} sm={24} md={5} lg={5}>
            <div className="followUs">
              {t('follow_us')}
            </div>
            <Row type="flex" justify="space-between" className="footerSocialRoom">
              <ReactSVG
                className="footerSocialIcon"
                src="/icons/Facebook.svg"
                onClick={(e) => this.openLink(e, 'facebook')}
              />
              <ReactSVG
                className="footerSocialIcon"
                src="/icons/Twitter.svg"
                onClick={(e) => this.openLink(e, 'twitter')}
              />
              <ReactSVG
                className="footerSocialIcon"
                src="/icons/Linkedin.svg"
                onClick={(e) => this.openLink(e, 'linkedin')}
              />
              <ReactSVG
                className="footerSocialIcon"
                src="/icons/Youtube.svg"
                onClick={(e) => this.openLink(e, 'youtube')}
              />
              <ReactSVG
                className="footerSocialIcon"
                src="/icons/Instagram.svg"
                onClick={(e) => this.openLink(e, 'instagram')}
              />
            </Row>
          </Col>
          <Col xs={0} sm={0} md={5} lg={5}>
            <div className="poweredBy" onClick={(e) => this.openExternalLink(e)}>
              <div style={{ marginRight: 5 }}>{t('powered_by')} </div><Logo size={132} />
            </div>
          </Col>
          <Col xs={24} sm={24} md={0} lg={0} style={{ marginTop: 25 }}>
            <div className="poweredBy" onClick={(e) => this.openExternalLink(e)}>
              <div style={{ marginRight: 5 }}>{t('powered_by')} </div><Logo size={100} />
            </div>
          </Col>
        </Row>
      </AntFooter>
    );
  }
}

const actions = {
  signout,
};
const enhanced = compose(
  withFirebase,
  withRouter,
  connect(state => ({
    auth: state.firebase.auth,
  }), actions),
  withTranslation(),
);
export default enhanced(Footer);
