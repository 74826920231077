import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import { configureStore } from './store'
import { ReactReduxFirebaseProvider } from 'react-redux-firebase'
import { createFirestoreInstance } from 'redux-firestore'
import history from './utils/history'
import firebase from './firebase'
import App from './app'
import registerServiceWorker from './registerServiceWorker'
import ReactGA from 'react-ga';

const store = configureStore()

const root = document.getElementById('root')

const __DEV__ = process.env.NODE_ENV === 'development'

const gaConfig = {
  trackingId: __DEV__ ? 'UA-138668001-3': 'UA-138668001-2',
  debug: true,
  gaOptions: {
    cookieDomain: 'none',
  },
}

const rrfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  updateProfileOnLogin: false,
}

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance,
  initializaAuth: true,
}

const render = Component => {
    return ReactDOM.render(
        <Provider store={store}>
          <ReactReduxFirebaseProvider {...rrfProps}>
            <Router history={history}>
                <Component />
            </Router>
          </ReactReduxFirebaseProvider>
      </Provider>,
      root
    )
}

if (module.hot) {
    module.hot.accept('./app', () => {
        const NextApp = require('./app').default
        render(NextApp)
    })
}

ReactGA.initialize(gaConfig)


// store.firebaseAuthIsReady.then(() =>
  render(App)
// )

registerServiceWorker()
