import React from 'react';
import { Layout } from 'antd';
import { Route, Redirect } from 'react-router-dom';
import axios from 'axios';
import Header from '../header/header';
import Footer from '../footer/footer';
import Sider from '../sider/sider';
import Loading from '../common-ui/loading';
import FeedbackButton from '../feedbackButton/feedbackButton';
import history from '../../utils/history';
import { withFirebase } from 'react-redux-firebase'
import { AUTHROUTE } from '../../routes';

const { Content } = Layout;

// let path = window.location.pathname.substring(1);
// console.log('MainLayout', {path})

class NewMainLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryData: [],
      travelFromData: [],
      travelToData: [],
    };
  }

  componentDidMount() {
    if (sessionStorage.getItem('travelFromCode') === undefined || sessionStorage.getItem('travelFromCode') === null) {
      history.push('/');
    }
    axios.get('https://api.culturemee.com/api/countries/getAll').then((response) => {
      // console.log({response});
      const countryData = response.data.data;
      const travelFromData = countryData.filter(item => item.code === sessionStorage.getItem('travelFromCode'))[0];
      const travelToData = countryData.filter(item => item.code === sessionStorage.getItem('travelToCode'))[0];
      // console.log('travelFromData', travelFromData);
      // console.log('travelToData', travelToData);
      this.setState({ countryData, travelFromData, travelToData });
    }).catch((error) => {
      console.log({error});
    });
  }

  componentDidUpdate(prevprops, prevstate) {
    const { travelFromData, travelToData } = this.state;
    if (travelFromData && travelToData) {
      if (travelFromData.code !== sessionStorage.getItem('travelFromCode') || travelToData.code !== sessionStorage.getItem('travelToCode')) {
        this.updateCountrySelected();
      }
    }
  }

  updateCountrySelected = () => {
    const { countryData } = this.state;
    this.setState({
      travelFromData: countryData.filter(item => item.code === sessionStorage.getItem('travelFromCode'))[0],
      travelToData: countryData.filter(item => item.code === sessionStorage.getItem('travelToCode'))[0],
    });
  }

  scrollTo = (value) => {
    // console.log('scrollTo', value);

    // setTimeout(() => {
      // window.scrollBy(0, 500);
    // }, 2000);

    // window.scrollTo({
    //   top: value,
    //   left: 0,
    //   behavior: 'smooth',
    // });

    // document.getElementById('mainLayout').scrollBy(0, value);
    document.querySelector('.mainDiv').scrollTop = value;

    console.log('scrollTo', value);
  }

  render() {
    const { firebase, component: Component, ...routerProps } = this.props;
    const { countryData, travelFromData, travelToData } = this.state;
    const auth = firebase.auth();
    const authenticated = auth.currentUser;

    let path = window.location.pathname.substring(1);

    if (!authenticated) {
      return <Redirect to={AUTHROUTE} />
    }

    if (countryData.length < 1) {
      return <Loading pastDelay={1} />;
    }

    return (
      <Route
        {...routerProps}
        render={matchProps => (
          <div id="mainDiv" className="mainDiv">
            <Layout id="mainLayout" style={{ minHeight: '100vh' }}>
              <Header>Header</Header>
              <Layout>
                <Sider width={400} path={path}>Sider</Sider>
                <Layout>
                  <Content className="mainLayoutContent" style={{ background: 'yellow' }}>
                    <div className="mainLayoutComponentContainer">
                      <Component {...matchProps} travelFromData={travelFromData} travelToData={travelToData} scrollTo={this.scrollTo} />
                    </div>
                  </Content>
                </Layout>
                <FeedbackButton />
              </Layout>
              <Footer>Footer</Footer>
            </Layout>
          </div>
        )}
      />
    );
  }
}

export default withFirebase(NewMainLayout);
