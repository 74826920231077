import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import GlobalEN from './translations/globalEN.json';
// import GlobalNL from "./translations/globalNL.json"
const resources = {
  en: {
    translation: GlobalEN,
  },
  // nl: {
  //   translation: GlobalNL
  // }
};
i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
