import React from 'react'
import { Layout } from 'antd'
import Header from '../header/header'
import { Route } from 'react-router-dom'
const { Content, Footer } = Layout;

class DefaultLayout extends React.Component {
  render() {
    const { component: Component, ...routerProps } = this.props;
    return (
      <Route
        {...routerProps}
        render={matchProps => (
          <div>
            <Layout style={{ minHeight: '100vh' }}>
              <Layout>
                {/*<Header />*/}
                <Content style={{ margin: '0 16px' }}>
                  <div style={{ padding: 24, minHeight: 360 }}>
                    <Component {...matchProps}/>
                  </div>
                </Content>
                {/*<Footer style={{ textAlign: 'center' }}>
                  LIZARD.GLOBAL © 2019
                </Footer>*/}
              </Layout>
            </Layout>
          </div>
      )}/>
    )
  }
}

export default DefaultLayout;
