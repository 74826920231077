import React from 'react';
import { Field, Fields, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import fieldConfig from './fieldconfig';
import '../feedbackButton.css';
// import 'braft-editor/dist/index.css';

function getField(props) {
  if (props.names) {
    return <Fields {...props} />;
  }
  return <Field {...props} />;
}

let FormFeedback = props => {
  const { handleSubmit } = props;

  return (
    <form onSubmit={handleSubmit}>
      <Row gutter={16}>
        {fieldConfig().map(({ layout, ...rest }) => (
          <Col key={rest.name} {...layout}>
            {rest.excludeFromForm ?
              rest.component
            :
            getField(rest)}
          </Col>
        ))}
      </Row>
    </form>
    );
};

const enhanced = compose(
  connect((state, props) => {
    const { record, submit } = props;

    if (record) {
      return {
        initialValues: record,
        onSubmit: submit,
      };
    }
    return {};
  }),
  reduxForm({
    enableReinitialize: true,
  }),
);
export default enhanced(FormFeedback);
