import './sider.css';
import React, { Component } from 'react';
import { Layout, Menu, Icon, Row, Col, Button, Collapse } from 'antd';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withFirebase } from 'react-redux-firebase';
import { withRouter, Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import ReactSVG from 'react-svg';
import ReactGA from 'react-ga';
import history from '../../utils/history';
import { signout } from '../../containers/auth/actions';
import { AUTHROUTE } from '../../routes';
import { coordinates } from '../../utils/calc';

const { Sider: AntSider } = Layout;
const SubMenu = Menu.SubMenu;
const Panel = Collapse.Panel;

const scratchTheSurface = ['culture-overview', 'business-culture', 'general-dos-&-donts', 'duty-of-care'];
const goDeeper = ['compare-your-culture','business-negotiation'];
const talkToThePros = ['culture-consultant','language-interpreter'];

class Sider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openKeys: [],
    };
  }

  componentDidMount() {
    const { path } = this.props;
    if (scratchTheSurface.includes(path)) {
      this.setState({ openKeys: ['2'] })
    } else if (goDeeper.includes(path)) {
      this.setState({ openKeys: ['3'] })
    } else if (talkToThePros.includes(path)) {
      this.setState({ openKeys: ['4'] })
    }
  }

  componentDidUpdate(prevprops, prevstate) {
    const { path } = this.props;
    if (prevprops.path !== path) {
      if (scratchTheSurface.includes(path)) {
        this.setState({ openKeys: ['2'], path })
      } else if (goDeeper.includes(path)) {
        this.setState({ openKeys: ['3'], path })
      } else if (talkToThePros.includes(path)) {
        this.setState({ openKeys: ['4'], path })
      }
    }
  }

  callback = (key) => {
    // console.log(key);
  }

  handleCollapse = (e, params) => {
    console.log(window.location.pathname)

    const { key, child } = params
    ReactGA.event({category: 'HeatMap', action: 'click', label: `pu:${window.location.pathname}|ct:Menu|rx:${coordinates(e).relative_x}|ry:${coordinates(e).relative_y}`})

    const { openKeys } = this.state;
    if (child) {
      this.setState({ openKeys: [key] }, history.push('/'+child));
    } else if (openKeys[0] !== key) {
      this.setState({ openKeys: [key] });
    } else {
      this.setState({ openKeys: [] });
    }
  }

  render() {
    const { t, path } = this.props;
    const { openKeys } = this.state;

    return (
      <AntSider className="siderContainer">
        <Row className="siderRoom">
          <Col xs={0} sm={0} md={24} lg={24} className="siderCol">
            <Button
              className={ path === 'culture-videos' ? "siderMainButton siderSelectedButton" : "siderMainButton"}
              onClick={(e) => this.handleCollapse(e, { key: '0', child: 'culture-videos' })}
            >
              {t('culture_videos')}
            </Button>
          </Col>
          {/*
          <Col xs={0} sm={0} md={24} lg={24} className="siderCol">
            <Collapse
              activeKey={openKeys}
              // defaultActiveKey={[]}
              // onChange={this.callback}
            >
              <Panel header="This is panel header 1" key="3">
                <p>{t('culture_overview')}</p>
                <p>{t('business_culture')}</p>
              </Panel>
            </Collapse>
            <Button className="siderMainButton haveChildren" onClick={() => this.handleCollapse(3)}>
              {t('scratch_the_surface')}
            </Button>
          </Col>
          */}
          <Col xs={0} sm={0} md={24} lg={24} className="siderCol" onClick={(e) => this.handleCollapse(e, { key: '2' })}>
            <Collapse
              activeKey={openKeys}
              accordion
              defaultActiveKey={scratchTheSurface.includes(path) ? '2' : ''}
            >
              <Panel className={ scratchTheSurface.includes(path) ? "siderSelectedButton" : "" } header={t('scratch_the_surface')} key="2">
                <div
                  className={ path === scratchTheSurface[0] ? "siderChildrenContainer siderSelectedChildren" : "siderChildrenContainer"}
                  onClick={(e) => this.handleCollapse(e, { key: '2', child: scratchTheSurface[0] })}
                >
                  <ReactSVG
                    className="subMenuDrawerIcon"
                    src="/icons/Culture Overview.svg"
                  />
                  {t('culture_overview')}
                </div>
                <div
                  className={ path === scratchTheSurface[1] ? "siderChildrenContainer siderSelectedChildren" : "siderChildrenContainer"}
                  onClick={(e) => this.handleCollapse(e, { key: '2', child: scratchTheSurface[1] })}
                >
                  <ReactSVG
                    className="subMenuDrawerIcon"
                    src="/icons/Business Culture.svg"
                  />
                  {t('business_culture')}
                </div>
                <div
                  className={ path === scratchTheSurface[2] ? "siderChildrenContainer siderSelectedChildren" : "siderChildrenContainer"}
                  onClick={(e) => this.handleCollapse(e, { key: '2', child: scratchTheSurface[2] })}
                >
                  <ReactSVG
                    className="subMenuDrawerIcon"
                    src="/icons/Do_s _ Don_ts.svg"
                  />
                  {t('general_dos_&_donts')}
                </div>
                <div
                  className={ path === scratchTheSurface[3] ? "siderChildrenContainer siderSelectedChildren" : "siderChildrenContainer"}
                  onClick={(e) => this.handleCollapse(e, { key: '2', child: scratchTheSurface[3] })}
                >
                  <ReactSVG
                    className="subMenuDrawerIcon"
                    src="/icons/Duty of Care.svg"
                  />
                  {t('duty_of_care')}
                </div>
              </Panel>
            </Collapse>
          </Col>
          <Col xs={0} sm={0} md={24} lg={24} className="siderCol" onClick={(e) => this.handleCollapse(e, { key: '3' })}>
            <Collapse
              activeKey={openKeys}
              accordion
              defaultActiveKey={goDeeper.includes(path) ? '3' : ''}
            >
              <Panel className={ goDeeper.includes(path) ? "siderSelectedButton" : "" } header={t('go_deeper')} key="3">
                <div
                  className={ path === goDeeper[0] ? "siderChildrenContainer siderSelectedChildren" : "siderChildrenContainer"}
                  onClick={(e) => this.handleCollapse(e, { key: '2', child: goDeeper[0] })}
                >
                  <ReactSVG
                    className="subMenuDrawerIcon"
                    src="/icons/Compare Your Culture.svg"
                  />
                  {t('compare_your_culture')}
                </div>
                <div
                  className={ path === goDeeper[1] ? "siderChildrenContainer siderSelectedChildren" : "siderChildrenContainer"}
                  onClick={(e) => this.handleCollapse(e, { key: '2', child: goDeeper[1] })}
                >
                  <ReactSVG
                    className="subMenuDrawerIcon"
                    src="/icons/Business Negotiation.svg"
                  />
                  {t('business_negotiation')}
                </div>
              </Panel>
            </Collapse>
          </Col>
          <Col xs={0} sm={0} md={24} lg={24} className="siderCol" onClick={(e) => this.handleCollapse(e, { key: '4' })}>
            <Collapse
              activeKey={openKeys}
              accordion
              defaultActiveKey={talkToThePros.includes(path) ? '4' : ''}
            >
              <Panel className={ talkToThePros.includes(path) ? "siderSelectedButton" : "" } header={t('talk_to_the_pros')} key="4">
                <div
                  className={ path === talkToThePros[0] ? "siderChildrenContainer siderSelectedChildren" : "siderChildrenContainer"}
                  onClick={(e) => this.handleCollapse(e, { key: '2', child: talkToThePros[0] })}
                >
                  <ReactSVG
                    className="subMenuDrawerIcon"
                    src="/icons/Culture Consultant.svg"
                  />
                  {t('culture_consultant')}
                </div>
                <div
                  className={ path === talkToThePros[1] ? "siderChildrenContainer siderSelectedChildren" : "siderChildrenContainer"}
                  onClick={(e) => this.handleCollapse(e, { key: '2', child: talkToThePros[1] })}
                >
                  <ReactSVG
                    className="subMenuDrawerIcon"
                    src="/icons/Language Interpreter.svg"
                  />
                  {t('language_interpreter')}
                </div>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </AntSider>
    )
  }
}

const actions = {
  signout
}
const enhanced = compose(
  withFirebase,
  withRouter,
  connect(state => ({
    auth: state.firebase.auth,
  }),
    actions
  ),
  withTranslation(),
);
export default enhanced(Sider);
