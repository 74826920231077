const sgMail = require('@sendgrid/mail');
sgMail.setApiKey(process.env.SENDGRID_API_KEY);

const sender = 'chathuri@lizard-apps.com'

export const sendVoucher = (email, voucher) => {
  new Promise((resolve, reject) => {
    const msg = {
      to: email,
      from: sender,
      subject: 'Here is your voucher code',
      html: '<p>Enter your voucher code <strong>voucher</strong> to access premium content in CultureMee Mobile App',
    };
    sgMail.send(msg).then(() => resolve())
    .catch(error => reject(error));
  });

}
